import { useState, useContext } from "react"
import { CredentialsContext } from "../../API/CredentialsContext"
import { v4 as uuidv4 } from "uuid"
import { Actions } from "../../Reducer/reducer"
import ChangeTransporterTable from "../../Tables/toolsChangeTransporter"

const ToolsChangeTransporter = () => {
    const [ctState, setCtState] = useState({
        credential: {},
        ewaybillNo: "",
        newTransporterId: ""
    })
    const [requests, setRequests] = useState([])
    const { state, dispatch } = useContext(CredentialsContext)
    const users = state?.credentials || []
    const isChangeTransporterButtonDisabled =
        Object.keys(ctState.credential).length === 0 ||
        ctState.ewaybillNo === "" ||
        ctState.newTransporterId === "" ||
        state.loading

    const handleState = (event) => {
        const { name, value } = event.target
        if (name === 'credential') {
            setCtState(prev => (
                {
                    ...prev,
                    [name]: users.find(u => u.id === +value)
                }
            ))
        } else {
            setCtState(prev => (
                {
                    ...prev,
                    [name]: value
                }
            ))
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleChangeTransporter(event)
        }
    }

    const handleChangeTransporter = async (event) => {
        event.preventDefault()
        try {
            dispatch({ type: Actions.ShowLoading })
            const payload = {
                ewaybillNos: ctState.ewaybillNo.split("\n"),
                newTransporterId: ctState.newTransporterId,
                credentials: {
                    userName: ctState.credential.userName,
                    password: ctState.credential.password,
                    gstNumber: ctState.credential.gstNumber,
                    loginType: "TR"
                }
            }
            const requestId = uuidv4().replaceAll("-", "").substring(0, 5)
            const response = await fetch('https://dev-api.e-waybill.in/api/e-waybill/change?sync=true',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ...payload, requestId })
                })
            if (response.ok) {
                const data = await response.json()
                setRequests([{ requestId, status: data.status, response: data, request: payload }])
            }
        }
        catch (error) { console.error('Error in changing transporter id:', error) }
        finally { dispatch({ type: Actions.HideLoading }) }
    }

    return (
        <form className="my-5 pt-5" onSubmit={handleChangeTransporter}>
            <div className="row">
                <div className="col-lg-3 m-5">
                    <h4>Change Transporter</h4>
                    <div className="form-group mb-3">
                        <label htmlFor='credential' className="mb-2">Credential</label>
                        <select className="form-select" onChange={handleState} name="credential" id='credential'>
                            <option>Select</option>
                            {
                                users.map((user, index) => {
                                    return (
                                        <option key={index} value={user.id}>{user.gstNumber}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <textarea
                            className="form-control"
                            style={{ height: "200px" }}
                            type="text"
                            name="ewaybillNo"
                            id="ewaybillNo"
                            placeholder="Enter EWaybill one per line"
                            onChange={handleState} />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor='newTransporterId' className="mb-2">New Transporter ID</label>
                        <input
                            className="form-control"
                            type="text"
                            name="newTransporterId"
                            id="newTransporterId"
                            placeholder="New Transporter ID"
                            onChange={handleState}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={handleChangeTransporter}
                            disabled={isChangeTransporterButtonDisabled}
                            onKeyDown={handleKeyDown}
                        >
                            Change Transporter
                        </button>
                    </div>
                </div>
                <div className="col-lg-7 m-5">
                    <div className="overflow-x-auto mb-3">
                        <ChangeTransporterTable requests={requests} />
                    </div>
                </div>
            </div>
        </form>
    )
}
export default ToolsChangeTransporter