const ExpringEWBTable = ({ ewbsToExtend, requests, expiringEWBState, handleEWBCheckbox, offset, PER_PAGE }) => {
    return (
        <table className="table table-sm">
            <thead className="table-secondary">
                <tr className="text-uppercase">
                    <th>Ewaybill No</th>
                    <th>Consignor GST</th>
                    <th>Consignor Address</th>
                    <th>Consignor Pin</th>
                    <th>Consignee GST</th>
                    <th>Consignee Address</th>
                    <th>Consignee Pin</th>
                    <th>Expiring On</th>
                    <th>
                        <button type="button"
                            className="btn btn-primary"
                            disabled={ewbsToExtend.length === 0}
                            data-bs-toggle="modal"
                            data-bs-target="#extendEWBModal">
                            Extend
                        </button>
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    (
                        requests[0]?.response.ewaybillDetails.length === 0 &&
                        requests[0]?.response.ewaybills.length === 0 &&
                        !requests[0]?.response.loginStatus
                    ) ?
                        (
                            <tr>
                                <td colSpan={9} className="text-danger text-center">
                                    No EWaybill Details Found For This Credential !
                                </td>
                            </tr>
                        ) :
                        (
                            requests[0]?.response.ewaybillDetails
                                .filter(item => item.ewaybill.includes(expiringEWBState.keyword))
                                .slice(offset, offset + PER_PAGE)
                                .map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.ewaybill}</td>
                                            <td>{item.consignorGst}</td>
                                            <td>{item.consignorAddr}</td>
                                            <td>{item.consignorPin}</td>
                                            <td>{item.consigneeGst}</td>
                                            <td>{item.consigneeAddr}</td>
                                            <td>{item.consigneePin}</td>
                                            <td>{item.validTill}</td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    id={item.ewaybill}
                                                    checked={ewbsToExtend.indexOf(item.ewaybill) > -1}
                                                    onChange={() => handleEWBCheckbox(item)} /> <label
                                                        htmlFor={item.ewaybill}>
                                                    Select
                                                </label>
                                            </td>
                                        </tr>
                                    )
                                })
                        )
                }
            </tbody>
        </table>
    )
}
export default ExpringEWBTable