import classNames from "classnames"

const PartAReturnToOriginTable = ({ handleDownloadExcel, requests }) => {
    return (
        <table className="table table-sm">
            <thead className="table-secondary">
                <tr className="text-uppercase">
                    <th>Forward EWB</th>
                    <th>RTO Ewaybill</th>
                    <th>Validity</th>
                    <th>
                        <button
                            className="btn btn-success"
                            onClick={handleDownloadExcel}>
                            Excel Download
                        </button>
                    </th>
                </tr>
            </thead>
            {
                requests.map((request, index) => {
                    return (
                        <tbody key={index}>
                            {
                                request.response.rtoEWaybills.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.ewaybillNo}</td>
                                            <td className={
                                                classNames({
                                                    'text-success': item.rtoEWaybill,
                                                    'text-danger': item.error,
                                                })}>
                                                {item.rtoEWaybill || item.error}
                                            </td>
                                            <td>{item.validTill}</td>
                                            <td></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    )
                })
            }
        </table>
    )
}
export default PartAReturnToOriginTable