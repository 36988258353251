import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faPen, faX } from "@fortawesome/free-solid-svg-icons"

const UsersTable = ({ userState, handleEditUser, handleEnableOrDisableUser }) => {
    return (
        <div className="col-lg-7 m-5 mt-3">
            <h4 className="col-3">Existing Users</h4>
            <table className="table">
                <thead>
                    <tr className="text-uppercase">
                        <th>User Name</th>
                        <th>Permissions</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {userState.users.map((user, index) => {
                        return (
                            <tr key={index}>
                                <td>{user.userName}</td>
                                <td>
                                    {user.permissions.map((permission, index) => {
                                        return (
                                            user.isAdmin ?
                                                "Admin" :
                                                <div key={index}>
                                                    {permission}
                                                </div>
                                        )
                                    })}
                                </td>
                                <td className="btn-group">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => handleEditUser(user)}
                                        type="button"
                                    >
                                        <FontAwesomeIcon
                                            icon={faPen}
                                            className="me-1"
                                        />
                                        Edit User
                                    </button>
                                    <button className={classNames("btn", {
                                        "btn-warning": user.enabled,
                                        "btn-success text-white": !user.enabled
                                    })}
                                        onClick={() => handleEnableOrDisableUser(user.id)}
                                        type="button">
                                        <FontAwesomeIcon
                                            icon={user.enabled ? faX : faCheck}
                                            className="me-1"
                                        />
                                        {user.enabled ? "Disable" : "Enable"} User
                                    </button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}
export default UsersTable