import classNames from "classnames"
import { ThreePulseCircles } from "../Animation/Animation"

const ExtendEWBTable = ({ requests, offset, PER_PAGE }) => {
    return (
        <table className="table table-bordered table-sm">
            <thead>
                <tr className="text-uppercase">
                    <th>SL.NO</th>
                    <th>Ewaybill No</th>
                    <th>AWB</th>
                    <th>Client</th>
                    <th>Curr location</th>
                    <th>Veh No</th>
                    <th>New Validity</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {
                    requests[0]?.request.items?.slice(offset, offset + PER_PAGE).map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{offset + index + 1}</td>
                                <td>
                                    <a
                                        className="text-decoration-none"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`https://ewaybillgst.gov.in/BillGeneration/EBPrint.aspx?cal=1&ewb_no=${item.ewb}`}
                                    >
                                        {item.ewb}
                                    </a>
                                </td>
                                <td>{item.wbn}</td>
                                <td>{item.client}</td>
                                <td>{item.currentPlace} - {item.currentPinCode} - {item.vehicleNo ? item.vehicleNo.slice(0, 2) : "None"}</td>
                                <td>{item.vehicleNo || ""}</td>
                                <td>{item.validTill}</td>
                                <td className={
                                    classNames({
                                        "text-danger": item.isFail,
                                        "text-success": item.isSuccess
                                    })
                                }>
                                    {item.isSuccess ? "EXTENDED" : (item.isFail ? item.status : <ThreePulseCircles />)}
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )
}
export default ExtendEWBTable