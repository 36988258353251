import React, { useCallback, useEffect, useState, useContext } from "react" // eslint-disable-line import/first
import { utils, writeFileXLSX } from 'xlsx'
import { CredentialsContext } from "../../API/CredentialsContext"
import { v4 as uuidv4 } from "uuid"
import { Actions } from "../../Reducer/reducer"
import { ThreePulseCircles } from "../../Animation/Animation"
import PartAReturnToOriginTable from "../../Tables/partAReturnToOriginTable"

const PartAReturnToOriginEWB = () => {
    const [requests, setRequests] = useState([])
    const [rtoState, setRtoState] = useState({
        credential: {},
        transporterId: "",
        ewaybillNos: "",
        returnReason: "Party Unavailable",
        documentType: "OTH"
    })
    const [checkbox, setCheckbox] = useState([])
    const { state, dispatch } = useContext(CredentialsContext)
    const users = state?.credentials || []
    const isGenerateButtonDisabled =
        Object.keys(rtoState.credential).length === 0 ||
        rtoState.documentType === "" ||
        rtoState.ewaybillNos === "" ||
        rtoState.returnReason === "" ||
        rtoState.transporterId === "" ||
        state.loading ||
        (
            requests[0]?.status !== undefined &&
            requests[0]?.status !== "COMPLETED"
        )

    const handleState = (event) => {
        const { name, value } = event.target
        if (name === 'credential') {
            setRtoState(prev => (
                {
                    ...prev,
                    [name]: users.find(u => u.id === +value)
                }
            ))
        } else {
            setRtoState(prev => (
                {
                    ...prev,
                    [name]: value
                }
            ))
        }
    }

    const handleCheckbox = (event) => {
        const index = checkbox.indexOf(event.target.value)
        if (index === -1) {
            setCheckbox([...checkbox, event.target.value])
        } else {
            setCheckbox(checkbox.filter((checkBox) => checkBox !== event.target.value))
        }
    }

    const handleGenerateRTOApi = async () => {
        try {
            dispatch({ type: Actions.ShowLoading })
            const payload = {
                credentials: {
                    userName: rtoState.credential.userName,
                    password: rtoState.credential.password,
                    gstNumber: rtoState.credential.gstNumber
                },
                ewaybillNos: rtoState.ewaybillNos.split("\n"),
                genPartB: checkbox.includes("genPartB"),
                taxInclusive: checkbox.includes("taxInclusive"),
                documentType: rtoState.documentType,
                transporterId: rtoState.transporterId,
                returnReason: rtoState.returnReason
            }
            const requestId = uuidv4().replaceAll("-", "").substring(0, 11)
            const response = await fetch('https://api.e-waybill.in/api/e-waybill/rto?sync=false',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ...payload, requestId })
                })
            if (response.ok) {
                const data = await response.json()
                setRequests([{ requestId, status: data.status, response: data, request: payload, checkCount: 0 }])
            } else {
                console.error("Error in getting response")
            }
        }
        catch (error) { console.error('Error in getting response from RTO EWB api:', error) }
    }

    const handleDownloadExcel = useCallback(() => {
        const newArray = requests[0]?.response.rtoEWaybills.map(({ ewaybillNo, rtoEWaybill, validTill }) => ({
            ewaybillNo,
            rtoEWaybill,
            validTill
        }))
        const ws = utils.json_to_sheet(newArray)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, "Data")
        writeFileXLSX(wb, "ReturnToOrigin.xlsx")
    }, [requests])

    useEffect(() => {
        const handleStatusCheck = async (r) => {
            if (r.status === 'COMPLETED' || r.checkCount > 10000) return r
            try {
                dispatch({ type: Actions.ShowLoading })
                const response = await fetch(`https://api.e-waybill.in/api/e-waybill/rto-status?request_id=${r.requestId}`)
                if (response.status === 200) {
                    const data = await response.json()
                    if (data.status === 'COMPLETED') {
                        return { ...r, status: 'COMPLETED', response: data, checkCount: r.checkCount + 1 }
                    } else {
                        return { ...r, status: data.status, checkCount: r.checkCount + 1 }
                    }
                } else {
                    return { ...r }
                }
            } catch (error) {
                console.error('Error in checking status of RTO EWB api:', error)
            } finally {
                dispatch({ type: Actions.HideLoading })
            }
            return r
        }

        const interval = setTimeout(() => {
            const checkForStatus = async () => {
                const results = await Promise.all(requests
                    .map(async (r) => {
                        return await handleStatusCheck(r)
                    }))
                setRequests(results)
            }
            checkForStatus().then(() => {
                console.info("check completed....")
            })
        }, 5000)
        return () => clearInterval(interval)
    }, [requests, dispatch])

    return (
        <div className="my-5 pt-5">
            <div className="row">
                <div className="col-lg-3 m-5">
                    <h4>Generate RTO E-Waybill</h4>
                    <div className="form-group mb-3">
                        <label htmlFor="credential" className="mb-2">Credential</label>
                        <select
                            className="form-select"
                            id="credential"
                            name="credential"
                            onChange={handleState}
                        >
                            <option>Select</option>
                            {users.map((user, index) => {
                                return (
                                    <option key={index} value={user.id}>{user.gstNumber}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="transporterId" className="mb-2">Transporter ID</label>
                        <input
                            className="form-control"
                            name="transporterId"
                            id="transporterId"
                            onChange={handleState}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="ewaybillNos" className="mb-2">Forward EWB Nos</label>
                        <textarea
                            className="form-control"
                            style={{ height: "200px" }}
                            name="ewaybillNos"
                            id="ewaybillNos"
                            placeholder="Enter Waybill Nos, One Per Line" onChange={handleState}
                        />
                    </div>
                    <div className="form-check mb-3">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="taxInclusive"
                            id="taxInclusive"
                            value="taxInclusive"
                            onChange={handleCheckbox}
                        />
                        <label htmlFor="taxInclusive" className="mb-2">Tax Inclusive?</label>
                    </div>
                    <div className="form-check mb-3">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="genPartB"
                            id="genPartB"
                            value="genPartB"
                            onChange={handleCheckbox}
                        />
                        <label htmlFor="genPartB" className="mb-2">Make Part-B?</label>
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="returnReason" className="mb-2">Return Reason</label>
                        <input
                            className="form-control"
                            name="returnReason"
                            id="returnReason"
                            onChange={handleState}
                            value={rtoState.returnReason}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="documentType" className="mb-2">Document Type</label>
                        <select
                            className="form-select"
                            name="documentType"
                            id="documentType"
                            onChange={handleState}
                        >
                            <option value="OTH">OTHERS</option>
                            <option value="DC">CHALLAN</option>
                        </select>
                    </div>
                    <button
                        className="btn btn-primary mb-5"
                        onClick={handleGenerateRTOApi}
                        disabled={isGenerateButtonDisabled}
                    >
                        {
                            (
                                requests[0]?.status !== undefined &&
                                requests[0]?.status !== "COMPLETED"
                            ) ?
                                <ThreePulseCircles /> : "Generate RTO"
                        }
                    </button>
                </div>
                <div className="col-lg-7 m-5">
                    <div className="row">
                        <h4>RTO Forms</h4>
                        <div className="overflow-x-auto mb-3">
                            <PartAReturnToOriginTable
                                handleDownloadExcel={handleDownloadExcel}
                                requests={requests}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default PartAReturnToOriginEWB