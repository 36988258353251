import { useState, useContext, useEffect } from "react"
import { CredentialsContext } from "../../API/CredentialsContext"
import { Actions } from "../../Reducer/reducer"
import ApiCallsTable from "../../Tables/ApiCallsTable"
import ApiCallsForm from "../../Form/apiCallsForm"

const APICalls = () => {

    const [apiState, setApiState] = useState({
        date: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date().getDate()}`,
        requestType: "GENERATE",
        showFailedItems: false,
        sortingOrder: "desc",
        apiResponse: [],
        jsonResponse: [],
        requestId: null
    })
    const [checkbox, setCheckbox] = useState(['autoUpdate'])
    const { dispatch } = useContext(CredentialsContext)
    const authToken = localStorage.getItem("authToken")

    const handleState = (event) => {
        const { name, value } = event.target
        setApiState(prev => (
            {
                ...prev,
                [name]: value
            }
        ))
    }

    const handleCheckbox = (event) => {
        const index = checkbox.indexOf(event.target.value)
        if (index === -1) {
            setCheckbox([...checkbox, event.target.value])
        } else {
            setCheckbox(checkbox.filter((checkBox) => checkBox !== event.target.value))
        }
    }

    const handleUpdate = async () => {
        try {

            dispatch({ type: Actions.ShowLoading })
            const url = `https://app.e-waybill.in/web-app/api?date=${apiState.date}&type=${apiState.requestType}&onlyFailed=${apiState.showFailedItems}&sort=${apiState.sortingOrder}`
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth-Token': authToken,
                }
            })

            if (response.ok) {
                const data = await response.json()
                setApiState((prev) => ({
                    ...prev,
                    apiResponse: data
                }))
                console.log("data", data)
            } else {
                console.error("Error in getting response from update/consolidate api")
            }
        } catch (error) {
            console.error("Unable to update", error)
        } finally { dispatch({ type: Actions.HideLoading }) }

    }

    const handleDownloadExcel = async () => {

        const url = `https://app.e-waybill.in/web-app/api/csv?x-auth-token=${authToken}`
        const formData = new FormData()
        formData.append('date', apiState.date)
        formData.append('type', apiState.requestType)
        formData.append('onlyFailed', apiState.showFailedItems)

        try {
            const response = await fetch(url, {
                method: 'POST',
                body: formData,
            })

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`)
            }

            const blob = await response.blob()
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${apiState.date}-${apiState.requestType}-requests`
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            console.log("File downloaded successfully!")
        } catch (error) {
            console.error("Failed to download file:", error)
        }
    }

    const handleViewModal = async (request_id) => {
        try {
            dispatch({ type: Actions.ShowLoading })
            const response = await fetch(
                `https://app.e-waybill.in/web-app/api/json?request_id=${request_id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth-Token': authToken,
                }
            })
            if (response.ok) {
                const data = await response.json()
                setApiState((prev) => ({
                    ...prev,
                    jsonResponse: data,
                    requestId:  request_id
                }))
                console.log("data", data)
            } else {
                console.error("Error in getting json response from api")
            }
        } catch (error) {
            console.error("Unable to fetch", error)
        } finally { dispatch({ type: Actions.HideLoading }) }
    }

    useEffect(() => {
        setApiState((prev) => ({
            ...prev,
            showFailedItems: checkbox.includes("showFailedItems"),
        }))
    }, [checkbox])


    useEffect(() => {

        const interval = setInterval(() => {
            handleUpdate()
        }, 10000)

        if (!checkbox.includes('autoUpdate')) {
            clearInterval(interval)
        }

        return () => clearInterval(interval)
    })

    return (
        <div className="my-5 pt-5 pb-2">
            <h4 className="m-5 text-center">API Calls</h4>
            <ApiCallsForm
                handleCheckbox={handleCheckbox}
                handleState={handleState}
                handleUpdate={handleUpdate}
                checkbox={checkbox}
                apiState={apiState} />
            <ApiCallsTable
                handleDownloadExcel={handleDownloadExcel}
                handleViewModal={handleViewModal}
                apiState={apiState}
            />
        </div>
    )
}
export default APICalls