import { ThreePulseCircles } from "../Animation/Animation"
import classNames from "classnames"

const ChangeTransporterTable = ({requests}) => {
    return (
        <table className="table table-borderless table-striped mt-4 mb-5">
            <thead>
                <tr className="text-uppercase table-dark">
                    <th>SL.NO</th>
                    <th>Ewaybill</th>
                    <th>New Transporter ID</th>
                    <th>Status</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {
                    requests[0]?.response.ewaybillNos.map((item, index) => {
                        const Response = requests[0].response
                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item}</td>
                                <td>{Response.changeStatus[item] ? Response.newTransporterId : ""}</td>
                                <td className={classNames({
                                    "text-danger": Response.errors[item],
                                    "text-success": Response.changeStatus[item]
                                })}
                                > {Response.changeStatus[item] ? 'Success' : Response.errors[item]}</td>
                                <td>{requests[0].status !== "COMPLETED" && < ThreePulseCircles />}</td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )
}

export default ChangeTransporterTable