import { ThreePulseCircles } from "../Animation/Animation"

const PartAGenerateMainTable = ({ handleDeleteAllReqFromDB, requestsDB, handleShowReqInDetail, handleDeleteEachReqFromDB }) => {
    return (
        <div className="overflow-x-auto mb-3">
            <table className="table table-sm">
                <thead className="table-secondary">
                    <tr className="text-uppercase">
                        <th>Request ID</th>
                        <th>Request Date</th>
                        <th>Status</th>
                        <th>Total</th>
                        <th>Success</th>
                        <th>Fail</th>
                        <th>
                            {
                                requestsDB.length !== 0 &&
                                <button
                                    className="btn btn-danger"
                                    onClick={handleDeleteAllReqFromDB}
                                >
                                    Clear All Requests</button>
                            }
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {requestsDB.map((req, index) => {
                        return (
                            <tr key={index}>
                                <td>{req.requestId}</td>
                                <td>{req.requestTimeStr}</td>
                                <td>{req.status}</td>
                                <td>{req.response.totalCount}</td>
                                <td>{req.response.successCount}</td>
                                <td>{req.response.failCount}</td>
                                <td>
                                    <span className="btn-group">
                                        <button
                                            className="btn btn-success"
                                            onClick={handleShowReqInDetail.bind(this, req)}
                                        >
                                            Select
                                        </button>
                                        <button
                                            className="btn btn-danger"
                                            onClick={handleDeleteEachReqFromDB.bind(this, req)}>
                                            Clear
                                        </button>
                                    </span>
                                </td>
                                <td>{req.status !== "COMPLETED" && <ThreePulseCircles />}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}
export default PartAGenerateMainTable