import classNames from "classnames";

const CheckReturnStatusTable = ({ data, gstin }) => {
    return (
        <table className="table table-borderless table-striped">
            <thead>
                <tr className="text-uppercase table-dark">
                    <th>SL.NO</th>
                    <th>GSTIN</th>
                    <th>Last Return</th>
                </tr>
            </thead>
            <tbody>
                {
                    data.slice(-gstin.length).map((item, index) => {
                        const Response = item.responseData
                        return (
                            <tr key={index} className={classNames({
                                "table-warning": Response.ewaybillError || Response.status,
                            })}>
                                <td>{index + 1}</td>
                                <td>{item.gstinNo}</td>
                                <td>
                                    {
                                        (Response.ewaybillError || Response.status) ?
                                            "ERROR" : Response[0]?.period
                                    }
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )
}

export default CheckReturnStatusTable