import { ThreePulseCircles } from "../Animation/Animation"

const PartBMainTable = ({ handleDeleteAllReqFromDB, requestsDB, pageUrl, handleDeleteEachReqFromDB, showReqInDetail }) => {
    return (
        <div className="overflow-x-auto mb-3">
            <table className="table table-sm">
                <thead className="table-secondary">
                    <tr className="text-uppercase">
                        <th>Request ID</th>
                        <th>Request Date</th>
                        <th>Status</th>
                        {
                            pageUrl === "#/part-b/consolidate" &&
                            <th>Consolidated EWB</th>
                        }
                        <th>Location</th>
                        <th>Vehicle No</th>
                        <th># of Items</th>
                        <th>
                            {
                                requestsDB.length !== 0 &&
                                <button className="btn btn-danger"
                                    onClick={handleDeleteAllReqFromDB}>
                                    Clear All Requests
                                </button>
                            }
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        requestsDB.map((req, index) => {
                            return (
                                <tr key={index}>
                                    <td>{req.requestId}</td>
                                    <td>{req.requestTimeStr}</td>
                                    <td>{req.status}</td>
                                    {
                                        pageUrl === '#/part-b/consolidate' &&
                                        <td>{req.response.consolidatedEWB}</td>
                                    }
                                    <td>{req.request.transhipmentLocation}</td>
                                    <td>{req.request.vehicleNo}</td>
                                    <td>{req.response.totalCount}</td>
                                    <td>
                                        <span className="btn-group">
                                            <button className="btn btn-primary"
                                                onClick={showReqInDetail.bind(this, req)}>
                                                Select
                                            </button>
                                            {
                                                req.request.consolidate && Object.keys(req.response.errors).length === 0 &&
                                                <a className="btn btn-success"
                                                    rel="noreferrer"
                                                    href={`https://api.e-waybill.in/api/e-waybill/print-trip?request_id=${req?.requestId}&cewb=${req?.response?.consolidatedEWB}`}
                                                    target="_blank">
                                                    Print
                                                </a>
                                            }
                                            <button
                                                className="btn btn-danger"
                                                onClick={handleDeleteEachReqFromDB.bind(this, req, index)}
                                            >
                                                Clear
                                            </button>
                                        </span>
                                    </td>
                                    <td>{req.status !== "COMPLETED" && <ThreePulseCircles />}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}
export default PartBMainTable