import { useEffect, useState, useContext } from "react"
import { v4 as uuidv4 } from "uuid"
import { useIndexedDB } from "react-indexed-db-hook"
import { CredentialsContext } from "../../API/CredentialsContext"
import PrintEWBTable from "../../Tables/printEWBTable"
import { Actions } from "../../Reducer/reducer"

const PrintEWB = () => {
    const [printEwbState, setPrintEwbState] = useState({
        credential: {},
        ewaybill: ""
    })
    const [requests, setRequests] = useState([])
    const [requestsDB, setRequestsDB] = useState([])
    const { getAll, add, update, clear, deleteRecord } = useIndexedDB("RequestsForPrintEWB")
    const { state, dispatch } = useContext(CredentialsContext)
    const users = state?.credentials || []
    const isSearchButtonDisabled =
        printEwbState.ewaybill.length < 12 ||
        Object.keys(printEwbState.credential).length === 0 ||
        state.loading

    const handleState = (event) => {
        const { name, value } = event.target
        if (name === 'credential') {
            setPrintEwbState(prevState => ({
                ...prevState,
                [name]: users.find(u => u.id === +value)
            }))
        } else {
            setPrintEwbState(prevState => ({
                ...prevState,
                [name]: value
            }))
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleSearchEWB(event)
        }
    }

    const handleSearchEWB = async (event) => {
        event.preventDefault()
        try {
            dispatch({ type: Actions.ShowLoading })
            const payload = {
                credentials: {
                    userName: printEwbState.credential.userName,
                    password: printEwbState.credential.password,
                    gstNumber: printEwbState.credential.gstNumber
                },
                ewaybill: printEwbState.ewaybill,
            }
            const requestId = uuidv4().replaceAll("-", "").substring(0, 11)
            const response = await fetch('https://prod-1.e-waybill.in/api/e-waybill/get',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ...payload, requestId }),
                })
            if (response.ok) {
                const data = await response.json()
                const requestTime = new Date().getTime()
                const requestTimeStr = new Date().toLocaleString('en-IN')
                await add({
                    requestId,
                    requestTime,
                    requestTimeStr,
                    status: data.status,
                    response: data,
                    request: payload,
                    checkCount: 0
                })
                setRequests(r => (
                    [...r, {
                        requestId,
                        requestTime,
                        requestTimeStr,
                        status: data.status,
                        response: data,
                        request: payload,
                        checkCount: 0
                    }]
                ))
            } else {
                console.error('error in getting response from ewb api')
            }
        }
        catch (error) { console.error('Error in getting response from print EWB api:', error) }
    }

    const handleDeleteAllReqFromDB = () => {
        const isConfirmed = window.confirm("You won't be able to read this data again, are you sure?")
        if (isConfirmed) {
            clear().then(() => {
                alert("Deleted all requests")
            })
        }
    }

    const handleDeleteEachReqFromDB = (entry) => {
        const isConfirmed = window.confirm("You won't be able to read this data again, are you sure?")
        if (isConfirmed) {
            deleteRecord(entry.requestId).then((event) => {
                alert(`${entry.requestId} was deleted`)
            })
            setRequests(r => r.filter(x => x.requestId !== entry.requestId))
        }
    }

    useEffect(() => {
        const handleStatusCheck = async (r) => {
            if (r.status === 'ACTIVE' || r.status === 'IN_ACTIVE' || r.status === 'INVALID' || r.checkCount > 10000) return r
            try {
                dispatch({ type: Actions.ShowLoading })
                const response = await fetch(`https://prod-1.e-waybill.in/api/e-waybill/get-status?request_id=${r.requestId}`)
                if (response.status === 200) {
                    const data = await response.json()
                    if (data.status === 'ACTIVE') {
                        update({ ...r, status: 'ACTIVE', response: data, checkCount: r.checkCount + 1 })
                        return { ...r, status: 'ACTIVE', response: data, checkCount: r.checkCount + 1 }
                    } else {
                        update({ ...r, status: data.status, response: data, checkCount: r.checkCount + 1 })
                        return { ...r, status: data.status, checkCount: r.checkCount + 1 }
                    }
                } else {
                    return { ...r }
                }
            }
            catch (error) { console.error('Error in checking status of print EWB api:', error) }
            finally { dispatch({ type: Actions.HideLoading }) }
            return r
        }

        (async () => {
            const r = await getAll()
            r.sort((a, b) => (a.requestTime - b.requestTime))
            setRequestsDB(r)
        })()

        const interval = setTimeout(() => {
            const checkForStatus = async () => {
                const results = await Promise.all(requests
                    .map(async (r) => {
                        return await handleStatusCheck(r)
                    }))
                setRequests(results)
            }
            checkForStatus().then(() => {
                console.info("check completed....")
            })
        }, 5000)
        return () => clearInterval(interval)
    }, [requests, dispatch, update, getAll])

    return (
        <form className="my-5 pt-5" onSubmit={handleSearchEWB}>
            <div className="row">
                <div className="col-lg-3 m-5">
                    <h4>Print EWB</h4>
                    <div className="form-group mb-3">
                        <label htmlFor='credential' className="mb-2">Credential</label>
                        <select
                            className="form-select"
                            id="credential"
                            name="credential"
                            onChange={handleState}
                        >
                            <option>Select</option>
                            {
                                users.map((user, index) => {
                                    return (
                                        <option key={index} value={user.id}>{user.gstNumber}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor='ewaybill' className="mb-2">EWaybill</label>
                        <input
                            className="form-control"
                            type="text"
                            name="ewaybill"
                            id="ewaybill"
                            placeholder="EWaybill #"
                            onChange={handleState}
                            maxLength="12"
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <button
                            className="btn btn-primary"
                            disabled={isSearchButtonDisabled}
                            type="submit"
                        >
                            Search
                        </button>
                    </div>
                </div>
                <div className="col-lg-8 my-5">
                    <div className="overflow-x-auto mb-5">
                        <PrintEWBTable
                            requestsDB={requestsDB}
                            handleDeleteAllReqFromDB={handleDeleteAllReqFromDB}
                            handleDeleteEachReqFromDB={handleDeleteEachReqFromDB}
                        />
                    </div>
                </div>
            </div>
        </form>
    )
}
export default PrintEWB