import { useEffect, useRef, useState } from "react"
import ReactPaginate from "react-paginate"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { prettyPrintJson } from "pretty-print-json";

const ApiResponse = ({ apiState, closed }) => {
    const ref = useRef()
    useEffect(() => {
        const a = ref.current
        a.showModal()
        return () => {
            a.close()
        }
    })
    const close = () => {
        ref.current?.close()
        closed()
    }
    return (
        <dialog className={"data-dialog"} ref={ref}>
            <div className="modal-content">
                <div className="modal-header d-flex justify-content-between mb-3">
                    <h1 className="modal-title fs-5" id="staticBackdropLabel">Response for {apiState?.requestId}</h1>
                    <button type="button" className="btn btn-warning" onClick={close} aria-label="Close">
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>
                <pre className="modal-body mb-3"
                    dangerouslySetInnerHTML={{ __html: prettyPrintJson.toHtml(apiState?.jsonResponse) }} />
                <div className="modal-footer">
                    <button type="button" className="btn btn-warning" onClick={close}>
                        <FontAwesomeIcon icon={faXmark} /> Cancel
                    </button>
                </div>
            </div>
        </dialog>
    )
}
const ApiCallsTable = ({ handleDownloadExcel, apiState, handleViewModal }) => {

    const PER_PAGE = 30
    const [currentPage, setCurrentPage] = useState(0)

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage)
    }

    const offset = currentPage * PER_PAGE
    const pageCount = Math.ceil(apiState.apiResponse?.length / PER_PAGE)

    const [show, setShow] = useState(false)

    useEffect(() => {
        const b = apiState.jsonResponse.hasOwnProperty("status")
        setShow(b)
    }, [apiState.requestId, apiState.jsonResponse])

    return (
        <>
            {
                apiState.apiResponse.length !== 0 && apiState.apiResponse.length > PER_PAGE &&
                <div className="my-5">
                    <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination  justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active primary"}
                    />
                </div>
            }
            <table className="table table-striped px-4">
                <thead className="table-dark">
                    <tr className="text-uppercase">
                        <th>SL.No</th>
                        <th>Request Id</th>
                        <th>Type</th>
                        <th>Time</th>
                        <th>Ewaybills</th>
                        <th>
                            <button className="btn btn-info" onClick={handleDownloadExcel}>Excel</button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        apiState.apiResponse
                            .slice(offset, offset + PER_PAGE)
                            .map((item, index) => {

                                const date = new Date(item.requestTime)
                                const hours = date.getHours()
                                const minutes = date.getMinutes()
                                const formattedHours = String(hours).padStart(2, '0')
                                const formattedMinutes = String(minutes).padStart(2, '0')
                                const timeString = `${formattedHours}:${formattedMinutes}`

                                const vehicleNo = item.misc.vehicleNo
                                const consolidatedEWB = item.misc.consolidatedEWB
                                const location = item.misc.location
                                const successCount = item.successCount
                                const failCount = item.failCount

                                return (
                                    <tr key={index}>
                                        <td>{offset + index + 1}</td>
                                        <td>{item.requestId}</td>
                                        <td>
                                            <div>
                                                {item.requestType}
                                            </div>
                                            <ul className="list-group mt-2">
                                                {vehicleNo && <li className="list-group-item">Vehicle No - {vehicleNo}</li>}
                                                {consolidatedEWB &&
                                                    <li className="list-group-item">CEWB No - {consolidatedEWB}</li>}
                                                {location && <li className="list-group-item">Location - {location}</li>}
                                            </ul>
                                        </td>
                                        <td>{timeString}</td>
                                        <td>
                                            {
                                                successCount !== 0 &&
                                                <div className="p-1">
                                                    <span>Success</span>
                                                    <small
                                                        className="border bg-success p-1 rounded text-white fw-bold ms-1">
                                                        {successCount}
                                                    </small>
                                                </div>
                                            }
                                            {
                                                failCount !== 0 && <div className="p-1">
                                                    <span>Failure</span>
                                                    <small className="border bg-danger p-1 rounded text-white fw-bold ms-1">
                                                        {failCount}
                                                    </small>
                                                </div>
                                            }
                                            <ul className="list-group mt-2">
                                                {
                                                    item.failedEwaybills.map((item, index) => {
                                                        return (
                                                            <li key={index}
                                                                className="bg-danger-subtle list-group-item text-danger border border-danger-subtle">{item}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                            <ul className="list-group">
                                                {
                                                    item.ewaybills.map((item, index) => {
                                                        return (
                                                            <li key={index} className="list-group-item">{item}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </td>
                                        <td>
                                            <button
                                                className="btn btn-info"
                                                type="button"
                                                onClick={() => handleViewModal(item.requestId)}
                                            >
                                                View
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                    }
                </tbody>
            </table>
            {show && <ApiResponse apiState={apiState} closed={() => setShow(false)}></ApiResponse>}
        </>
    )
}
export default ApiCallsTable