import classNames from "classnames"
import { PrintTableHeader } from "../Data/printTableHeader"
import { ThreePulseCircles } from "../Animation/Animation"

const PrintMultipleEWBTable = ({ handleDownloadExcel, requests }) => {
    return (
        <table className="table table-borderless table-striped mb-5">
            <thead>
                <tr className="text-uppercase table-dark">
                    {PrintTableHeader.map((title, index) => {
                        return <th key={index}>{title}</th>
                    })}
                    <th>
                        <div className="btn-group">
                            <button
                                className="btn btn-success text-uppercase"
                                onClick={handleDownloadExcel}>
                                Excel
                            </button>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className="btn btn-info"
                                href={`https://api.e-waybill.in/api/e-waybill/bulk-print?request_id=${requests[0]?.requestId}`}>
                                Print All
                            </a>
                        </div>
                    </th>
                </tr>
            </thead>
            {
                requests.map((request, index) => {
                    return (
                        <tbody key={index}>
                            {request.response.ewaybills.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.ewaybill}</td>
                                        <td>
                                            {item.consignorGst} <br />
                                            {item.consignorAddr}
                                        </td>
                                        <td>
                                            {item.consigneeGst} <br />
                                            {item.consigneeAddr}
                                        </td>
                                        <td>
                                            {item.invoiceNo} <br />
                                            {item.invoiceDt} <br />
                                            {item.invoiceVal}
                                        </td>
                                        <td>{item.transporterId}</td>
                                        <td>{new Date(item.generatedAt).toDateString()}</td>
                                        <td>{new Date(item.validTill).toDateString()}</td>
                                        <td className={classNames({
                                            "text-success": item.valid,
                                            "text-danger": !item.valid
                                        })}>
                                            {item.valid ? "Valid EWaybill" : "Invalid EWaybill"}
                                        </td>
                                        <td>
                                            {
                                                request.status !== "COMPLETED" ?
                                                    <ThreePulseCircles /> :
                                                    (item.valid ? "COMPLETED" : "ERROR")
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    )
                })}
        </table >
    )
}
export default PrintMultipleEWBTable