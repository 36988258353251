import React, { useCallback, useEffect, useState, useContext } from "react" // eslint-disable-line import/first
import { utils, writeFileXLSX } from 'xlsx'
import { CredentialsContext } from "../../API/CredentialsContext"
import { v4 as uuidv4 } from "uuid"
import PrintMultipleEWBTable from "../../Tables/printMultipleEWBTable"
import { Actions } from "../../Reducer/reducer"

const PrintMultipleEWB = () => {
    const [printState, setPrintState] = useState({
        credential: {},
        ewaybillNos: ""
    })
    const [requests, setRequests] = useState([])
    const { state, dispatch } = useContext(CredentialsContext)
    const users = state?.credentials || []
    const isSearchButtonDisabled =
        Object.keys(printState.credential).length === 0 ||
        printState.ewaybillNos === "" ||
        state.loading

    const handleState = (event) => {
        const { name, value } = event.target
        if (name === 'credential') {
            setPrintState(prevState => (
                {
                    ...prevState,
                    [name]: users.find(u => u.id === +value)
                }
            ))
        } else {
            setPrintState(prevState => (
                {
                    ...prevState,
                    [name]: value
                }
            ))
        }
    }

    const handleSearchEWB = async () => {
        try {
            dispatch({ type: Actions.ShowLoading })
            const payload = {
                credentials: {
                    userName: printState.credential.userName,
                    password: printState.credential.password,
                    gstNumber: printState.credential.gstNumber
                },
                ewaybillNos: printState.ewaybillNos.split('\n')
            }
            const requestId = uuidv4().replaceAll("-", "").substring(0, 11)
            const response = await fetch('https://api.e-waybill.in/api/e-waybill/bulk-get?for=print',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ...payload, requestId: requestId })
                })
            if (response.ok) {
                const data = await response.json()
                setRequests([{ requestId, status: data.status, response: data, request: payload, checkCount: 0 }])
            }
        }
        catch (error) { console.error('Error in getting response from print multiple EWB api:', error) }
    }

    const handleDownloadExcel = useCallback(() => {
        const newArray = requests[0]?.response?.ewaybills.map(({
            generatedAt,
            ewaybill,
            consignorGst,
            consignorAddr,
            consignorPin,
            consigneeGst,
            consigneeAddr,
            consigneePin,
            invoiceNo,
            invoiceVal,
            invoiceDt,
            transporterId,
            valid,
            validTill }) => ({
                EWB: ewaybill,
                CONSIGNOR_GST: consignorGst,
                CONSIGNOR_ADDRESS: consignorAddr,
                CONSIGNOR_PIN: consignorPin,
                CONSIGNEE_GST: consigneeGst,
                CONSIGNEE_ADDRESS: consigneeAddr,
                CONSIGNEE_PIN: consigneePin,
                INVOICE_NO: invoiceNo,
                INVOICE_VALUE: invoiceVal,
                INVOICE_DATE: invoiceDt,
                TRANSPORTER_ID: transporterId,
                GENRATED_AT: generatedAt,
                EXPIRY: validTill,
                MESSAGE: validTill ? "SUCCESS" : "ERROR",
            }))
        const ws = utils.json_to_sheet(newArray)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, "Data")
        writeFileXLSX(wb, `bulk-excel-${printState.credential.gstNumber}.xlsx`)
    }, [requests])

    useEffect(() => {
        const handleStatusCheck = async (r) => {
            if (r.status === 'COMPLETED' || r.checkCount > 10000) return r
            try {
                dispatch({ type: Actions.ShowLoading })
                const response = await fetch(`https://api.e-waybill.in/api/e-waybill/bulk-get-status?request_id=${r.requestId}&for=print`)
                const data = await response.json()
                if (data.status === 'COMPLETED') {
                    return { ...r, status: 'COMPLETED', response: data, checkCount: r.checkCount + 1 }
                } else {
                    return { ...r, status: data.status, checkCount: r.checkCount + 1 }
                }
            }
            catch (error) { console.error('Error in checking status of print multiple EWB api:', error) }
            finally { dispatch({ type: Actions.HideLoading }) }
            return r
        }

        const interval = setTimeout(() => {
            const checkForStatus = async () => {
                const results = await Promise.all(requests
                    .map(async (r) => {
                        return await handleStatusCheck(r)
                    }))
                setRequests(results)
            }
            checkForStatus().then(() => {
                console.info("check completed....")
            })
        }, 5000)
        return () => clearInterval(interval)
    }, [requests, dispatch])

    return (
        <div className="my-5 pt-5">
            <div className="row">
                <div className="col-lg-3 mt-5">
                    <h4>Print Multiple EWB</h4>
                    <div className="form-group mb-3">
                        <label htmlFor='credential' className="mb-2">Credential</label>
                        <select
                            className="form-select"
                            id="credential"
                            name="credential"
                            onChange={handleState}
                        >
                            <option>Select</option>
                            {
                                users.map((user, index) => {
                                    return (
                                        <option key={index} value={user.id}>{user.gstNumber}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <textarea
                            className="form-control"
                            style={{ height: "200px" }}
                            type="text"
                            name="ewaybillNos"
                            id="ewaybillNos"
                            placeholder="EWaybill #, one per line"
                            onChange={handleState}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <button
                            className="btn btn-primary"
                            onClick={handleSearchEWB}
                            disabled={isSearchButtonDisabled}
                        >
                            Search
                        </button>
                    </div>
                </div>
                <div className="col-lg-9 my-5">
                    <div className="overflow-x-auto mb-5">
                        <PrintMultipleEWBTable
                            handleDownloadExcel={handleDownloadExcel}
                            requests={requests}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PrintMultipleEWB